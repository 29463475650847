.homeTwoOuter {
  /* background: white; */
}
.homeTwoInner {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.homeTwoInnerFirst {
  margin-bottom: 6%;
}
.homeTwoInnerLast {
  margin-top: 6%;
}

.h2Main {
  color: var(--foreground);
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 3.625rem;
  letter-spacing: -0.4px;
}
.h3Sub {
  color: var(--foreground);
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2.625rem;
}
.para {
  color: var(--foreground);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 10px;
}
.cardOuter {
  display: flex;
  justify-content: space-between;
  margin: 25px;
}
.card {
  width: 30%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  padding: 25px 15px;
}
.card h3 {
  margin-bottom: 8px;
}
.card p {
  font-size: 0.8rem;
}
